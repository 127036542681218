import { EnvironmentConfiguration } from '@trade4sure/t4s-lib';

export enum ErrorResponseEnums {
  CompanyGradePending = 'CompanyGradePendingResponse',
  InsuranceRiskTooHigh = 'InsuranceRiskTooHighResponse',
  InsuranceQuotationLimitReached ='InsuranceQuotationLimitReachedResponse'
}

export const errorResponseMap: EnvironmentConfiguration['additionalRequestErrorMap'] =
  {
    API: {
      MollieErrorResponse: [
        {
          message: 'Er is iets misgegaan binnen het verkeer naar Mollie.',
        },
      ],
      InvalidIbanResponse: [
        {
          property: 'iban',
          message: 'Uw IBAN-nummer is ongeldig.',
        },
        {
          message: 'Uw IBAN-nummer is ongeldig.',
        },
      ],
      TooManyDebtorTestsForDowngradeResponse: [
        {
          message:
            'Annuleer eerst de geplande downgrade (van uw subscriptie), om een nieuwe toetsing af te kunnen sluiten.',
        },
      ],
      CouldNotUpdateOrCreateContactResponse: [
        {
          message: 'Het vewerken van de contactgegevens is mislukt.',
        },
      ],
      AlreadyActiveClaimResponse: [
        {
          message:
            'Wegens een actieve schadeclaim voor deze debiteur, is het niet mogelijk om een nieuwe verzekering af te sluiten.',
        },
      ],
    },
    Data: {
      // [ErrorResponseEnums.CompanyGradePending]: [
      //   {
      //     message: 'De grade is nog in afwachting',
      //   },
      // ],
      // [ErrorResponseEnums.InsuranceRiskTooHigh]: [
      //   {
      //     message: 'Het risico is te hoog',
      //   }
      // ],
      [ErrorResponseEnums.InsuranceQuotationLimitReached]: [
        {
          message: 'Het maximale aantal afgewezen offertes is bereikt',
        }
      ]
    },
  };
