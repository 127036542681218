import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { ProgressStepperModel } from "../base-components/stepper-base.component";
import { SubscriptionProduct } from 'src/app/modules/api-client/models/product/product';

@Injectable({
    providedIn: 'root'
})
export class ProgressStepperCommunicationService<T extends ProgressStepperModel> {
    private canBeSavedSubject = new ReplaySubject<boolean>(1);
    public canBeSaved$ = this.canBeSavedSubject.asObservable();

    private isBeingSavedSubject = new Subject<boolean>();
    public isBeingSaved$ = this.isBeingSavedSubject.asObservable();
    
    public model: T | undefined;
    public selectedSubscriptionProduct: SubscriptionProduct | undefined;

    public emitCanBeSaved(canBeSaved: boolean): void {
        this.canBeSavedSubject.next(canBeSaved);
    }

    public emitIsBeingSaved(isBeingSaved: boolean): void {
        this.isBeingSavedSubject.next(isBeingSaved);
    }
}