import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionProduct, SubscriptionProducts } from '../../models/product/product';
import { ControllerBase } from '@trade4sure/t4s-lib';

@Injectable()
export class SubscriptionProductControllerService extends ControllerBase {
  protected resourceName = 'SubscriptionProduct';

  public getAll(): Observable<SubscriptionProducts | undefined> {
    return this.get();
  }

  public getProduct(productId: string): Observable<SubscriptionProduct | undefined> {
    return this.get([productId]);
  }
}
